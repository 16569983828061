// Translated
// Migrated 
<template>
  <div class="wizard">
    <div
      v-for="(stepItem, i) in steps"
      :key="i"
      class="wizard-step"
      :class="{ subactive: step > i + 1, active: step == i + 1 }"
    >
      <span class="hidden md:inline">
        {{ stepItem }}
      </span>
      <span
        class="inline md:hidden text-center"
        data-i18n="step"
      >{{ $t('step') }}<br />{{ i + 1 }}</span>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    step: {
      type: Number,
      required: true,
    },

    steps: {
      type: Array,
      required: true,
    },
  },
})
</script>
